import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/node/work/src/templates/post.template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "the-normal-way-to-change-ips"
    }}>{`The normal way to change IPs`}</h2>
    <p>{`It's very handy as a programmer to change your public facing IP. Whether you
want to pretend your computer is in a different country or just want to get
around an IP ban, being able to masquerade your IP has many uses.`}</p>
    <p>{`The workflow `}<strong parentName="p">{`I've`}</strong>{` always used to achieve this is:`}</p>
    <ol>
      <li parentName="ol">{`running `}<inlineCode parentName="li">{`openvpn`}</inlineCode>{` as a background process`}</li>
      <li parentName="ol">{`do what I want to do`}</li>
      <li parentName="ol">{`kill the `}<inlineCode parentName="li">{`openvpn`}</inlineCode>{` process`}</li>
    </ol>
    <p>{`When I was testing some IP banning tooling I was using this workflow and
quickly came across the problem that switching locations/IPs multiple times
gets annoying as I have to kill and start `}<inlineCode parentName="p">{`openvpn`}</inlineCode>{` multiple times. So I
wanted to build a utility to make it easier by collapsing this workflow into
just one step.`}</p>
    <p>{`The ideal experience I wanted was to run a command isolated from the
rest of my local network, yet be connected to a VPN whose location I can
change with a single argument.`}</p>
    <h2 {...{
      "id": "coding-it-up"
    }}>{`Coding it up`}</h2>
    <p>{`Since I wanted isolation for this tool, I chose `}<inlineCode parentName="p">{`podman`}</inlineCode>{` as it's my goto
container engine for my one off isolation needs. The simplest place I started
was with a quick script to build an `}<inlineCode parentName="p">{`ovpn`}</inlineCode>{` alpine image like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`NAME=ovpn
cat << EOF > /tmp/Dockerfile.\${NAME}
FROM alpine:3.12
RUN apk add --no-cache openvpn bash
EOF
EMPTY=$(mktemp -d)
podman build -q -t \${NAME} -f /tmp/Dockerfile.\${NAME} $EMPTY 2>&1 > /dev/null
`}</code></pre>
    <p>{`The immediate problem I saw with this approach was that the container image
doesn't include any of my local tools. It didn't seem practical to keep
adding dependencies as needed to this image. This would cause image bloat and
I would have to rebuild the image any time I want to add something.`}</p>
    <p>{`I wanted a way to run this image as a conduit for my other tools.`}</p>
    <p>{`The solution I eventually reached was to simply `}<strong parentName="p">{`run`}</strong>{` the isolated `}<inlineCode parentName="p">{`openvpn`}</inlineCode>{`
container, `}<strong parentName="p">{`then`}</strong>{` run a command I wanted `}<strong parentName="p">{`in`}</strong>{` the containers network
namespace. That way I get the network isolation I want, `}<strong parentName="p">{`with`}</strong>{` the ability to
isolate the entire execution of a command behind a VPN.`}</p>
    <p>{`To do this I ran the container with privileges to create a `}<inlineCode parentName="p">{`tun`}</inlineCode>{` interface
like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`podman run --rm --cap-add NET_ADMIN,NET_RAW --device /dev/net/tun \\
       --name \${CONTAINER_NAME} \\
       -v /tmp/ovpn/:/tmp/ovpn/ \\
       -it -d \${NAME} \\
       bash -c "
       openvpn \\
       --config $FILE \\
       --auth-nocache \\
       --auth-retry nointeract \\
       --dev $tun \\
       --dev-type tun \\
       --errors-to-stderr \\
       --auth-user-pass <(echo -e '$USERNAME\\n$PASSWORD')
       " 1>&2
`}</code></pre>
    <p>{`Then I used `}<inlineCode parentName="p">{`nsenter`}</inlineCode>{` to run a command in the context of the containers
network namespace:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`...
PID=$(podman inspect \${CONTAINER_NAME} | jq -r '.[0] | .State.Pid')
nsenter -U -n -t $PID $@
`}</code></pre>
    <p>{`This worked just as expected, and when the command finishes, the container can
be stopped and removed for seamless cleanup.`}</p>
    <p>{`Hooray containers and namespaces! 📦🎊`}</p>
    <p>{`Wrapping this all together in a script `}<inlineCode parentName="p">{`run_behind_vpn`}</inlineCode>{`, I can now run a
command masquerading as different IPs like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`#!/usr/bin/env bash
export USERNAME=$USERNAME
export PASSWORD=$PASSWORD
export US=$(find /tmp/ovpn/*us* -print -quit) # find a US ovpn file
export JAPAN=$(find /tmp/ovpn/*jp* -print -quit) # find a JP ovpn file
run_behind_vpn $US -- curl -s ipinfo.io | jq -r '.country'
run_behind_vpn $JAPAN -- curl -s ipinfo.io | jq -r '.country'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`US
JP
`}</code></pre>
    <p>{`Now if I ever need to pretend that I'm running my computer from a different
country or need a new IP, I have a method to do so.`}</p>
    <p>{`Try the script out for yourself `}<a parentName="p" {...{
        "href": "https://github.com/cmrfrd/dotfiles/blob/master/scripts/run_behind_vpn"
      }}>{`here`}</a>{` and thanks for reading!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      